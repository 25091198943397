<template>
  <div>
    <div class="compont_p">多店管理</div>
    <p class="title">当前登录账号</p>
    <div class="login">
      <div class="flex-box">
        <!-- <p class="com">{{ info.organization_name }}</p> -->
        <p class="com">{{ userData.organization_name }}</p>
        <p v-if="isMain" class="com main-title">[主账号]</p>
      </div>

      <div class="flex_sp_c">
        <p>会员名：{{ userData.cname }}</p>
        <div class="btn flex_c_c" @click="dialogFormVisible = true">添加账号绑定</div>
      </div>
    </div>

    <p class="title">当前绑定账号</p>

    <el-table :data="tableData" class="mt_20">
      <el-table-column type="index" width="100" label="序号" align="center" />
      <el-table-column prop="username" label="登录账号" align="center" />
      <el-table-column prop="dwmc" label="单位名称" align="center" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button size="mini" v-if="scope.row.can_change != 102" @click="handleClick(scope.row)">切换此账号</el-button>
          <el-button size="mini" v-if="scope.row.show_del == 101" type="danger" @click="handleDelete(scope.row)">解除关联</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog title="添加账号绑定" :visible.sync="dialogFormVisible">
      <el-form :model="form" ref="accountForm" :rules="formRules">
        <el-form-item prop="username" label="登录账号：">
          <el-input v-model="form.username" autocomplete="new-password" style="width: 300px" />
        </el-form-item>
        <el-form-item prop="pwd" label="登录密码：">
          <el-input type="password" v-model="form.pwd" autocomplete="new-password" style="width: 300px" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleAdd('accountForm')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { LgetItem, LsetItem } from "@/utils/storage";
import FingerprintJS from "fingerprintjs2";
import md5 from "md5";
export default {
  data() {
    return {
      isMain: false,
      canchange: true,
      tableData: [],
      userData: {},
      info: LgetItem("info"),
      form: {
        password: "", //密码md5加密
        username: "", //账号
        pwd: "", //密码
      },
      formRules: {
        username: [{ required: true, message: "请输入账号", trigger: "change" }],
        pwd: [{ required: true, message: "请输入密码", trigger: "change" }],
      },
      dialogFormVisible: false,
    };
  },
  created() {
    this.get();
    this.$api("account.getMyInfo").then((res) => {
      if (res.data.f_member_id == res.data.id) {
        this.isMain = true;
        LsetItem("canchange", true);
        this.canchange = LgetItem("canchange");
      } else {
        this.isMain = false;
        LsetItem("canchange", false);
        this.canchange = LgetItem("canchange");
      }
      this.userData = res.data;
    });
  },
  methods: {
    get() {
      this.$api("account.getAccountList").then((res) => {
        this.tableData = res.data.list;
      });
    },
    handleAdd(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          FingerprintJS.get((components) => {
            const values = components.map((component, index) => {
              if (index === 0) {
                //把微信浏览器里UA的wifi或4G等网络替换成空,不然切换网络会ID不一样
                return component.value.replace(/\bNetType\/\w+\b/, "");
              }
              return component.value;
            });
            // 生成最终id murmur
            const device_id = FingerprintJS.x64hash128(values.join(""), 31);
            console.log("设备标识----", device_id);
            this.$api("account.bindAccount", {
              ...this.form,
              userpass: md5(this.form.pwd),
              device_id: device_id,
            }).then((res) => {
              if (res.code == 200) {
                this.$message.success("绑定成功");
                this.$refs[formName].resetFields();
                this.dialogFormVisible = false;
                this.get();
              }
            });
          });
        }
      });
    },
    handleClick(row) {
      this.$api("account.switchAccount", { id: row.id }).then((res) => {
        console.log("切换账号后的信息-------", res);
        LsetItem("token", res.data.token);
        // LsetItem("info", res.data);
        if (this.isMain) {
          LsetItem("canchange", true);
        }
        this.$api("account.getInfo").then((re) => {
          if (re.code == 200) {
            console.log("切换账号成功-----", res.data);
            LsetItem("info", re.data);
            localStorage.setItem("user_info", JSON.stringify(re.data));
            this.$store.dispatch("getBadge");
            // this.$router.push("/");
            location.reload();
          }
        });
      });
    },
    handleDelete(row) {
      this.$confirm("是否解除关联账号?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$api("account.delAccount", { id: row.id }).then(() => {
            this.$message.success("解除关联成功");
            this.get();
          });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="less" scoped>
.flex-box {
  display: flex;
  flex-direction: row;
}
.main-title {
  margin-left: 10px;
}
.title {
  color: #333;
  font-size: 14px;
  margin: 20px 0;
}
.login {
  width: 308px;
  padding: 20px;
  box-sizing: border-box;
  background: @themeColor;
  border-radius: 8px;
  color: #ffffff;
  .com {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .btn {
    width: 99px;
    height: 32px;
    background: #ffffff;
    border-radius: 16px;
    color: @themeColor;
    cursor: pointer;
    font-size: 12px;
  }
}
</style>
